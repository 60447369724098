import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2a98e004"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "assignments-panel-wayline-job-edit"
};
var _hoisted_2 = {
  key: 0,
  "class": "wrapperPanel"
};
var _hoisted_3 = {
  "class": "header"
};
var _hoisted_4 = {
  key: 0,
  "class": "inner"
};
var _hoisted_5 = {
  key: 1,
  "class": "inner"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["WaylineMapPreview"], {
    "class": "kml-Map"
  }), $setup.wrapperOpen ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode($setup["DeepSvgIcon"], {
    name: "arrow-left",
    "class": "icon",
    onClick: $setup.handleClose
  }), _createElementVNode("div", null, _toDisplayString($setup.wrapperOpen === 'drone' ? '设备' : $setup.wrapperOpen === 'wayline' ? '航线' : '未知'), 1 /* TEXT */)]), $setup.wrapperOpen === 'wayline' ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode($setup["WaylineSelectorPanel"], {
    modelValue: $setup.jobData.waylineId,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.jobData.waylineId = $event;
    }),
    lockAircraftEnumKey: $setup.lockAircraftEnumKey,
    onChange: $setup.handleWaylineFile
  }, null, 8 /* PROPS */, ["modelValue", "lockAircraftEnumKey"])])) : _createCommentVNode("v-if", true), $setup.wrapperOpen === 'drone' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.docks, function (item) {
    return _openBlock(), _createBlock($setup["DockSelectorItem"], {
      item: item,
      modelValue: $setup.jobData.droneId,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
        return $setup.jobData.droneId = $event;
      }),
      key: item.aircraftSerialNumber
    }, null, 8 /* PROPS */, ["item", "modelValue"]);
  }), 128 /* KEYED_FRAGMENT */))])) : _createCommentVNode("v-if", true)])) : _createCommentVNode("v-if", true)]);
}