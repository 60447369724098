import { createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-15f661b4"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "assignments"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createCommentVNode("    <MqttDebug></MqttDebug>"), _createCommentVNode("    <MqttLog></MqttLog>"), _createVNode(_Transition, {
    name: "slide-fade-transform",
    mode: "out-in"
  }, {
    "default": _withCtx(function () {
      return [(_openBlock(), _createBlock(_resolveDynamicComponent($setup.useComponent)))];
    }),
    _: 1 /* STABLE */
  })]);
}