import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-010cb73c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "dock-item"
};
var _hoisted_2 = {
  "class": "content"
};
var _hoisted_3 = {
  "class": "aaa"
};
var _hoisted_4 = {
  key: 0,
  "class": "ccc"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["DeepSvgIcon"], {
    name: "server",
    "class": "icon"
  }), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($props.item.droneName), 1 /* TEXT */), _createCommentVNode("      <div class=\"bbb\">{{ item.gatewaySerialNumber }}</div>"), !((_a = $props.item) === null || _a === void 0 ? void 0 : _a.aircraftSerialNumber) ? (_openBlock(), _createElementBlock("div", _hoisted_4, " 当前选择机场未匹配飞行器，请在选择航线的时候注意选择对应的飞行器航线 ")) : _createCommentVNode("v-if", true)])]);
}