import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_checkbox = _resolveComponent("el-checkbox");
  return _openBlock(), _createBlock($setup["DeepCell"], {
    title: $props.item.droneName,
    label: $props.item.gatewaySerialNumber,
    showDivider: ""
  }, {
    icon: _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        name: "server",
        "class": "icon"
      })];
    }),
    "default": _withCtx(function () {
      return [_createVNode(_component_el_checkbox, {
        modelValue: $setup.checked,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.checked = $event;
        })
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title", "label"]);
}